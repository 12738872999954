import type { FC } from 'react'

import { isNil } from 'ramda'

import { trimToCharLength } from 'src/Util'

import { BaserowFieldType } from '../lib/enums'
import numberInputFormatter from '../lib/numberInputFormatter'
import type { AnyBaserowField, BarChartAggregateType } from '../lib/types'

import type { GroupOption } from './ChartFunctions/getChartData'

interface ChartTooltipProps {
  payload?: any[]
  active?: boolean
  isGrouped?: boolean
  groupOptions?: GroupOption[]
  aggregateField?: AnyBaserowField
  aggregateType?: BarChartAggregateType
  matchingField?: AnyBaserowField
}

const ChartTooltip: FC<ChartTooltipProps> = ({
  payload = [],
  active = false,
  isGrouped = false,
  groupOptions = [],
  aggregateField,
  aggregateType = 'count',
  matchingField,
}) => {
  const aggregateBy: BarChartAggregateType = aggregateType ?? 'count'
  const tooltipPayload = payload?.[0]?.payload ?? {
    name: 'Undefined',
    value: 0,
  }
  const isToFormatAggregateField =
    aggregateField?.type === BaserowFieldType.NUMBER &&
    aggregateBy !== 'count' &&
    aggregateBy !== 'distinct'
  //matchingField is x axis dataField
  const isToFormatDataField = matchingField?.type === BaserowFieldType.NUMBER

  const formatNumberField = (
    value: string | number,
    isToFormat: boolean,
    fieldToUse: AnyBaserowField,
  ) => {
    if (isToFormat) {
      if (typeof value === 'number') value = value.toString()
      const decimalPlaces = fieldToUse?.number_decimal_places ?? 0
      const spaceSeparator = fieldToUse?.number_separator
      const prefix = fieldToUse?.number_prefix
      const suffix = fieldToUse?.number_suffix
      return numberInputFormatter(
        spaceSeparator,
        value,
        prefix,
        suffix,
        decimalPlaces,
      )
    } else {
      if (!value) return ''
      return value
    }
  }

  if (active && tooltipPayload) {
    return (
      <div className="max-h-[400px] max-w-[400px] overflow-hidden border bg-white p-3">
        <p className="label">{`${formatNumberField(trimToCharLength(tooltipPayload?.name, 25), isToFormatDataField, matchingField)} : ${formatNumberField(tooltipPayload?.value, isToFormatAggregateField, aggregateField)}`}</p>
        {isGrouped &&
          groupOptions?.map((option) => {
            return !isNil(tooltipPayload[option?.name]) ? (
              <p
                key={option?.name}
                className="label mt-1 flex items-center gap-1 text-sm text-gray-500"
              >
                <div
                  className="h-4 w-4 min-w-4 flex-shrink-0 rounded-full"
                  style={{ backgroundColor: option?.hex }}
                ></div>
                {`${trimToCharLength(option?.name, 25)} : ${tooltipPayload[option?.name]}`}
              </p>
            ) : null
          })}
      </div>
    )
  }

  return null
}

export default ChartTooltip
