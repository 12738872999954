import { useState, type Dispatch, type FC, type SetStateAction } from 'react'

import * as IconSet from '@heroicons/react/24/outline'
import { PencilSquareIcon } from '@heroicons/react/24/solid'
import { TextField } from '@mui/material'
import {
  DeleteColor,
  DeleteColorVariables,
  type CreateColor,
  type CreateColorVariables,
  type UpdateColor,
  type UpdateColorVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import {
  QUERY as ColorQuery,
  type RecordColor,
} from 'src/components/HubDash/CardExpand/InfoPanel/ColorModuleCell'
import { QUERY as LayoutQuery } from 'src/components/HubDash/HubDashLayoutListCell'
import { getHexForColor } from 'src/components/HubDash/lib/baserow/baserowColors'
import type { HubDashCardType } from 'src/components/HubDash/lib/types'
import Button from 'src/components/Library/Button/Button'
import { SearchField } from 'src/components/Library/SearchField/SearchField'

interface ColorModuleProps {
  colorList: RecordColor[]
  selectedBorderColor: string
  setSelectedBorderColor: Dispatch<SetStateAction<string>>
  selectedBackgroundColor: string
  setSelectedBackgroundColor: Dispatch<SetStateAction<string>>
  card: HubDashCardType
  view: any // not typed yet
  records: any[] // not typed yet
}

const ColorModule: FC<ColorModuleProps> = ({
  colorList,
  selectedBorderColor,
  setSelectedBorderColor,
  selectedBackgroundColor,
  setSelectedBackgroundColor,
  view,
  records,
  card,
}) => {
  const [editingColorId, setEditingColorId] = useState<number | null>(null)
  const [selectedIcon, setSelectedIcon] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [selectedDescription, setSelectedDescription] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const [iconSearchValue, setIconSearchValue] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const CREATE_COLOR = gql`
    mutation CreateColor($input: CreateHubDashColorMapInput!) {
      createHubDashColorMap(input: $input) {
        id
        name
        description
        colorName
        icon
        type
      }
    }
  `

  const resetEditState = () => {
    setEditingColorId(null)
    setSelectedIcon('')
    setSelectedName('')
    setSelectedDescription('')
    setSelectedType('')
  }

  const [createColor] = useMutation<CreateColor, CreateColorVariables>(
    CREATE_COLOR,
    {
      onCompleted: () => {
        resetEditState()
        setIsSaving(false)
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: ColorQuery, variables: { hubDashCardId: card.id } },
        { query: LayoutQuery },
      ],
    },
  )

  const UPDATE_COLOR = gql`
    mutation UpdateColor($id: Int!, $input: UpdateHubDashColorMapInput!) {
      updateHubDashColorMap(id: $id, input: $input) {
        id
        name
        description
        colorName
        icon
      }
    }
  `

  const [updateColor] = useMutation<UpdateColor, UpdateColorVariables>(
    UPDATE_COLOR,
    {
      onCompleted: () => {
        resetEditState()
        setIsSaving(false)
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: ColorQuery, variables: { hubDashCardId: card.id } },
        { query: LayoutQuery },
      ],
    },
  )

  const DELETE_COLOR = gql`
    mutation DeleteColor($id: Int!) {
      deleteHubDashColorMap(id: $id) {
        id
      }
    }
  `

  const [deleteColor] = useMutation<DeleteColor, DeleteColorVariables>(
    DELETE_COLOR,
    {
      onCompleted: () => {
        resetEditState()
        setIsDeleting(false)
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        { query: ColorQuery, variables: { hubDashCardId: card.id } },
        { query: LayoutQuery },
      ],
    },
  )

  const leftBorderDecoration = view?.decorations?.find(
    (decoration) => decoration.type === 'left_border_color',
  )

  const backgroundDecoration = view?.decorations?.find(
    (decoration) => decoration.type === 'background_color',
  )

  const hasBackgroundColor =
    backgroundDecoration?.value_provider_conf?.colors?.length > 0

  const borderColorOrder =
    leftBorderDecoration?.value_provider_conf?.colors?.map(
      (color) => color.color,
    ) ?? []

  const backgroundColorOrder =
    backgroundDecoration?.value_provider_conf?.colors?.map(
      (color) => color.color,
    ) ?? []

  const allBorderColorList = []
  const allBackgroundColorList = []

  allBorderColorList.push({
    id: 0,
    name: 'All Records',
    description: view.name,
    colorName: 'white',
    recordCount: records.length,
    icon: 'HomeIcon',
  })

  allBackgroundColorList.push({
    id: 0,
    name: 'All Records',
    description: view.name,
    colorName: 'white',
    recordCount: records.length,
    icon: 'HomeIcon',
  })

  for (const colorItem of colorList) {
    if (colorItem.type === 'background_color') {
      const backgroundColorItem = {
        id: colorItem.id,
        exists: true,
        type: 'background_color',
        name: colorItem.name,
        description: colorItem.description,
        colorName: colorItem.colorName,
        icon: colorItem.icon,
        recordCount: records.filter((record) => {
          if (record?.decorators?.background_color?.length > 0) {
            return record.decorators.background_color.some(
              (color) => color.color === colorItem.colorName,
            )
          }
          return false
        }).length,
      }
      allBackgroundColorList.push(backgroundColorItem)
    } else {
      const borderColorItem = {
        id: colorItem.id,
        type: 'left_border_color',
        exists: true,
        name: colorItem.name,
        description: colorItem.description,
        colorName: colorItem.colorName,
        icon: colorItem.icon,
        recordCount: records.filter((record) => {
          if (record?.decorators?.left_border_color?.length > 0) {
            return record.decorators.left_border_color.some(
              (color) => color.color === colorItem.colorName,
            )
          }
          return false
        }).length,
      }

      allBorderColorList.push(borderColorItem)
    }
  }

  let viewUnknownBorderColors = []
  let viewUnknownBackgroundColors = []

  const isSingleSelectBorderColor =
    leftBorderDecoration?.value_provider_type === 'single_select_color'
  const isSingleSelectBackgroundColor =
    backgroundDecoration?.value_provider_type === 'single_select_color'

  if (isSingleSelectBorderColor) {
    const fieldId = leftBorderDecoration.value_provider_conf?.field_id
    for (const record of records) {
      for (const field of record.fields) {
        if (field.id === fieldId && field.value) {
          if (
            !viewUnknownBorderColors.some((item) => item.id === field.value.id)
          ) {
            viewUnknownBorderColors.push(field.value)
          }
        }
      }
    }
    viewUnknownBorderColors = viewUnknownBorderColors.filter((viewColor) => {
      return !colorList.some((color) => color.colorName === viewColor.color)
    })
  } else {
    viewUnknownBorderColors =
      leftBorderDecoration?.value_provider_conf?.colors?.filter((viewColor) => {
        return !colorList.some((color) => color.colorName === viewColor.color)
      }) ?? []
  }

  if (isSingleSelectBackgroundColor) {
    const fieldId = backgroundDecoration.value_provider_conf?.field_id
    for (const record of records) {
      for (const field of record.fields) {
        if (field.id === fieldId && field.value) {
          if (
            !viewUnknownBackgroundColors.some(
              (item) => item.id === field.value.id,
            )
          ) {
            viewUnknownBackgroundColors.push(field.value)
          }
        }
      }
    }
    viewUnknownBackgroundColors = viewUnknownBackgroundColors.filter(
      (viewColor) => {
        return !colorList.some((color) => color.colorName === viewColor.color)
      },
    )
  } else {
    viewUnknownBackgroundColors =
      backgroundDecoration?.value_provider_conf?.colors?.filter((viewColor) => {
        return !colorList.some((color) => color.colorName === viewColor.color)
      }) ?? []
  }

  for (const colorItem of viewUnknownBorderColors) {
    allBorderColorList.push({
      id: colorItem.id,
      exists: false,
      type: 'left_border_color',
      name: isSingleSelectBorderColor ? colorItem.value : 'Unknown',
      description: 'Color not defined',
      colorName: colorItem.color,
      recordCount: records.filter((record) => {
        if (record?.decorators?.left_border_color?.length > 0) {
          return record.decorators.left_border_color.some(
            (color) => color.color === colorItem.color,
          )
        }
        return false
      }).length,
      icon: '',
    })
  }

  for (const colorItem of viewUnknownBackgroundColors) {
    allBackgroundColorList.push({
      id: colorItem.id,
      exists: false,
      type: 'background_color',
      name: isSingleSelectBackgroundColor ? colorItem.value : 'Unknown',
      description: 'Color not defined',
      colorName: colorItem.color,
      recordCount: records.filter((record) => {
        if (record?.decorators?.background_color?.length > 0) {
          return record.decorators.background_color.some(
            (color) => color.color === colorItem.color,
          )
        }
        return false
      }).length,
      icon: '',
    })
  }

  const sortColorList = (list, order) => {
    if (!order.length) return

    list.sort((a, b) => {
      if (a.id === 0) return -1
      if (b.id === 0) return 1

      const indexA = order.indexOf(a.colorName)
      const indexB = order.indexOf(b.colorName)

      if (indexA === -1 && indexB === -1) return 0
      if (indexA === -1) return 1
      if (indexB === -1) return -1
      return indexA - indexB
    })
  }

  sortColorList(allBorderColorList, borderColorOrder)
  sortColorList(allBackgroundColorList, backgroundColorOrder)

  const handleSave = (colorItem) => {
    setIsSaving(true)
    const isBackgroundColor = colorItem.type === 'background_color'
    const colorName = isBackgroundColor
      ? selectedBackgroundColor
      : selectedBorderColor

    if (colorItem.exists) {
      updateColor({
        variables: {
          id: colorItem.id,
          input: {
            name: selectedName,
            description: selectedDescription,
            colorName: colorName,
            icon: selectedIcon,
          },
        },
      })
    } else {
      createColor({
        variables: {
          input: {
            name: selectedName,
            description: selectedDescription,
            colorName: colorName,
            icon: selectedIcon,
            hubDashCardId: card.id,
            type: selectedType,
          },
        },
      })
    }
  }

  const handleDelete = (colorItem) => {
    setIsDeleting(true)

    const id = Number(colorItem.id)
    if (typeof id !== 'number' || isNaN(id)) {
      setIsDeleting(false)
      throw new Error('Invalid color id')
    }

    deleteColor({ variables: { id: colorItem.id } })
  }

  const colorCard = (colorItem, isBackgroundColor = false) => {
    let ColorIcon = IconSet[colorItem.icon]
    const allColorsIcons = Object.entries(IconSet).map(([name, Icon]) => ({
      name,
      icon: Icon,
    }))

    const isSelected = isBackgroundColor
      ? selectedBackgroundColor === colorItem.colorName
      : selectedBorderColor === colorItem.colorName

    const handleColorSelect = () => {
      if (isBackgroundColor) {
        setSelectedBackgroundColor(colorItem.colorName)
      } else {
        setSelectedBorderColor(colorItem.colorName)
      }
    }

    const isEditing = editingColorId === colorItem.id

    if (isEditing && colorItem.id !== 0) {
      ColorIcon = IconSet[selectedIcon]
      return (
        <div
          key={colorItem.id}
          className={`flex w-full flex-col gap-3 rounded-lg p-2 hover:bg-gray-100 ${
            isSelected ? 'bg-gray-100' : ''
          }`}
          onClick={handleColorSelect}
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
        >
          <SearchField value={iconSearchValue} onChange={setIconSearchValue} />
          <div className="flex max-h-48 flex-wrap gap-2 overflow-scroll">
            {allColorsIcons
              .filter((iconItem) =>
                iconItem.name
                  .toLowerCase()
                  .includes(iconSearchValue.toLowerCase()),
              )
              .map((iconItem) => (
                <button
                  key={iconItem.name}
                  className="h-8 w-8 rounded-md bg-gray-400"
                  onClick={(e) => {
                    e.stopPropagation()
                    setSelectedIcon(iconItem.name)
                  }}
                  style={{
                    backgroundColor:
                      selectedIcon === iconItem.name
                        ? getHexForColor(colorItem.colorName)
                        : 'gray',
                  }}
                >
                  <iconItem.icon className="h-8 w-8 text-white" />
                </button>
              ))}
          </div>
          <div className="flex items-center gap-2">
            <div
              className={'mb-auto h-8 w-8 rounded-md'}
              style={{ backgroundColor: getHexForColor(colorItem.colorName) }}
            >
              {ColorIcon && (
                <ColorIcon
                  className={`h-8 w-8 ${
                    colorItem.id === 0 ? 'text-gray-600' : 'text-white'
                  }`}
                />
              )}
            </div>
            <p className="text-gray-600">
              {selectedIcon && selectedIcon !== '' ? selectedIcon : 'No Icon'}
            </p>
          </div>
          <TextField
            value={selectedName}
            onChange={(e) => setSelectedName(e.target.value)}
            label="Name"
            size="small"
            className="bg-white"
            onClick={(e) => e.stopPropagation()}
          />
          <TextField
            value={selectedDescription}
            onChange={(e) => setSelectedDescription(e.target.value)}
            label="Description"
            size="small"
            className="bg-white"
            multiline
            maxRows={3}
            onClick={(e) => e.stopPropagation()}
          />
          <div className="flex w-full justify-between">
            <div>
              <Button
                className="bg-red-500 text-white hover:bg-red-400"
                onClick={(e) => {
                  e.stopPropagation()
                  handleDelete(colorItem)
                }}
                disabled={isSaving}
                loading={isDeleting}
              >
                Delete
              </Button>
            </div>
            <div className="flex gap-2">
              <div>
                <Button
                  className="bg-gray-100 text-gray-500 hover:bg-gray-200"
                  onClick={(e) => {
                    e.stopPropagation()
                    resetEditState()
                  }}
                  disabled={isSaving || isDeleting}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSave(colorItem)
                  }}
                  disabled={isDeleting}
                  loading={isSaving}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div
        key={colorItem.id}
        className={`flex flex-row items-center justify-between gap-2 rounded-lg p-2 hover:bg-gray-100 ${
          isSelected ? 'bg-gray-100' : ''
        }`}
        onClick={handleColorSelect}
        role="button"
        tabIndex={0}
        onKeyDown={() => {}}
      >
        <div className="flex gap-2">
          <div
            className={'mb-auto h-6 w-6 rounded-md'}
            style={{ backgroundColor: getHexForColor(colorItem.colorName) }}
          >
            {ColorIcon && (
              <ColorIcon
                className={`h-6 w-6 ${
                  colorItem.id === 0 ? 'text-gray-600' : 'text-white'
                }`}
              />
            )}
          </div>
          <div className="flex flex-col">
            <p className="text-sm">{colorItem.name}</p>
            {isSelected && (
              <p className="text-sm text-gray-600">{colorItem.description}</p>
            )}
          </div>
        </div>
        <div className="flex items-center gap-2">
          {colorItem.id !== 0 && isSelected && (
            <button
              className="rounded-lg p-1 hover:bg-gray-200"
              onClick={(e) => {
                e.stopPropagation()
                setEditingColorId(colorItem.id)
                setSelectedIcon(colorItem.icon)
                setSelectedName(colorItem.name)
                setSelectedDescription(colorItem.description)
                setSelectedType(colorItem.type)
              }}
            >
              <PencilSquareIcon className="h-5 w-5 text-gray-500" />
            </button>
          )}
          <p className="flex items-center justify-center rounded-lg bg-indigo-100 px-2 text-xs text-indigo-500">
            {colorItem.recordCount}
          </p>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col p-2 px-4">
      <p className="pb-2 pt-2 text-sm uppercase text-gray-500">
        Filter By Background Colour
      </p>
      {allBackgroundColorList.map((colorItem) => colorCard(colorItem, true))}

      <p className="pb-2 pt-2 text-sm uppercase text-gray-500">
        Filter By Flag Colour
      </p>
      {allBorderColorList.map((colorItem) => colorCard(colorItem, false))}
    </div>
  )
}

export default ColorModule
