import { clamp, median, sum, isNil } from 'ramda'

import type { AnyBaserowField } from '../../lib/types'

export const MAX_DECIMAL_COUNT = 3

export const getSummaryTypeValueSum = (
  summaryField: AnyBaserowField,
  values: number[],
): number => {
  const availableValues = values?.filter((value) => !isNil(value))

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }
  return Number(
    sum(availableValues).toFixed(
      clamp(
        0,
        MAX_DECIMAL_COUNT,
        summaryField?.number_decimal_places ?? MAX_DECIMAL_COUNT,
      ),
    ),
  )
}

export const getSummaryTypeValueAverage = (
  summaryField: AnyBaserowField,
  values: number[],
): number => {
  const availableValues = values?.filter((value) => !isNil(value))

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }

  return Number(
    (sum(availableValues) / availableValues?.length).toFixed(
      clamp(
        0,
        MAX_DECIMAL_COUNT,
        summaryField?.number_decimal_places ?? MAX_DECIMAL_COUNT,
      ),
    ),
  )
}

export const getSummaryTypeValueMin = (
  summaryField: AnyBaserowField,
  values: number[],
): number => {
  const availableValues = values
    ?.filter((value) => !isNil(value))
    ?.sort((a, b) => a - b)

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }

  return Number(
    Number(availableValues[0] ?? 0).toFixed(
      clamp(
        0,
        MAX_DECIMAL_COUNT,
        summaryField?.number_decimal_places ?? MAX_DECIMAL_COUNT,
      ),
    ),
  )
}

export const getSummaryTypeValueMax = (
  summaryField: AnyBaserowField,
  values: number[],
): number => {
  const availableValues = values
    ?.filter((value) => !isNil(value))
    ?.sort((a, b) => b - a)

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }

  return Number(
    Number(availableValues[0] ?? 0).toFixed(
      clamp(
        0,
        MAX_DECIMAL_COUNT,
        summaryField?.number_decimal_places ?? MAX_DECIMAL_COUNT,
      ),
    ),
  )
}

export const getSummaryTypeValueMedian = (
  summaryField: AnyBaserowField,
  values: number[],
): number => {
  const availableValues = values
    ?.filter((value) => !isNil(value))
    ?.sort((a, b) => a - b)

  // Exit Early
  if (!availableValues?.length) {
    return 0
  }
  return Number(
    median(availableValues).toFixed(
      clamp(
        0,
        MAX_DECIMAL_COUNT,
        summaryField?.number_decimal_places ?? MAX_DECIMAL_COUNT,
      ),
    ),
  )
}

export const numberFormatter = (value: number): number => {
  return Number(value.toFixed(clamp(0, MAX_DECIMAL_COUNT, MAX_DECIMAL_COUNT)))
}
