import { isNil, median, sum, mean } from 'ramda'

import { isBucketField } from '../../CardSettingsDrawer/ChartSettings/BarChartDataSettings'
import { baserowColors, getHexForColor } from '../../lib/baserow/baserowColors'
import { CHART_EMPTY_COLOR } from '../../lib/constants'
import {
  BaserowFieldType,
  CardType,
  type BucketByValues,
} from '../../lib/enums'
import type {
  AnyBaserowField,
  BarChartAggregateType,
  BarChartOrder,
  BarChartSort,
} from '../../lib/types'

import { bucketChartData } from './bucketChartData'
import { getFieldDisplayValues } from './getFieldDisplayValues'
import { sortChartData } from './sortChartData'
import {
  getSummaryTypeValueAverage,
  getSummaryTypeValueMax,
  getSummaryTypeValueMedian,
  getSummaryTypeValueMin,
  getSummaryTypeValueSum,
  numberFormatter,
} from './summaryFunctions'

type ChartDataItem = {
  name: string
  value: number
  hex: string
  color: string
  records: any[] // not typed yet
}

// Custom type with Specified keys used for ReCharts
// groupByDataKey is for Grouped Charts that require specific key and value for each stack on a bar in the chart
// i.e.  { name: 'Staff Member', "Team 1": 1, "Team 2": 0, "Team 3": 1, }
export type ChartItem = ChartDataItem & {
  [groupByDataKey: string]: number | string | any[]
}

export type GroupOption = {
  name: string
  hex: string
}

export type ChartSortSettings = {
  sortBy: BarChartSort
  sortOrder: BarChartOrder
}

const reservedChartItemKeys = ['name', 'value', 'hex', 'color', 'records']

// Select Fields require predetermined options
const SELECT_FIELDS = [
  BaserowFieldType.SINGLE_SELECT,
  BaserowFieldType.MULTIPLE_SELECT,
]

const getValueAsNumber = (value: string): number => {
  const valueAsNumber = Number(value)
  // If it can be a number - return the number
  if (!isNaN(valueAsNumber) && !isNil(value)) {
    return valueAsNumber
  } else {
    // If valid string - return 1
    if (value) {
      return 1
    } else {
      return null
    }
  }
}

// Get Chart Data = used for Pie and Bar Charts in HubDash
export const getChartData = (
  dataField: AnyBaserowField,
  records: any[], // not typed yet
  chartType: CardType,
  aggregateType: BarChartAggregateType = 'count',
  includeEmptyRecords: boolean = false,
  bucketValuesBy: BucketByValues = null,
  yAxisType: 'recordCount' | 'fieldSummary' = 'recordCount',
  sortSettings: ChartSortSettings = null,
  groupByField: AnyBaserowField = null,
  aggregateField: AnyBaserowField = null,
): { chartDataItems: ChartItem[]; groupByOptions: GroupOption[] } => {
  let chartDataItems: ChartItem[] = []
  const groupByOptions: GroupOption[] = []

  const aggregateBy: BarChartAggregateType = aggregateType ?? 'count'

  // Step 1:
  // Gather the options
  const fieldUsesSelectOptions = SELECT_FIELDS.includes(dataField?.type)

  let colorIndex = 0

  //prepare aggregate field values into format of [{"name": "xxx", value: [1,2,3,...]}]
  const aggregateFieldsValues = []
  for (const record of records) {
    const stringValues = getFieldDisplayValues(
      record?.getCellValue(dataField?.name),
      dataField,
    )
    const value = record?.getCellValue(aggregateField?.name)
    for (const stringValue of stringValues) {
      if (
        !aggregateFieldsValues.find((option) => option.name === stringValue)
      ) {
        aggregateFieldsValues.push({
          name: stringValue,
          recordsWithValue: [
            { recordId: record.id, recordValue: getValueAsNumber(value) },
          ],
          values: [getValueAsNumber(value)],
        })
      } else {
        const aggregateFieldValue = aggregateFieldsValues.find(
          (option) => option.name === stringValue,
        )
        aggregateFieldValue.recordsWithValue.push({
          recordId: record.id,
          recordValue: getValueAsNumber(value),
        })
        aggregateFieldValue.values.push(getValueAsNumber(value))
      }
    }
  }

  for (const record of records) {
    const stringValues = getFieldDisplayValues(
      record?.getCellValue(dataField?.name),
      dataField,
    )

    for (const stringValue of stringValues) {
      // Check if a select option matches the color
      const aggregateFieldValues = aggregateFieldsValues.find(
        (option) => option.name === stringValue,
      ).values
      const matchingSelectOption =
        dataField?.select_options?.find(
          (option) => option?.value === stringValue,
        ) ?? null

      // Determine Color
      const color =
        fieldUsesSelectOptions && matchingSelectOption
          ? matchingSelectOption?.color
          : baserowColors[colorIndex % baserowColors.length].name

      // Get initial results based on aggregate type
      switch (aggregateBy) {
        case 'count': {
          // Count the Records - only push one for value
          if (!chartDataItems?.find((option) => option.name === stringValue)) {
            // If not already found - add new option to chart
            chartDataItems.push({
              name: stringValue,
              value: aggregateFieldValues.length,
              values: aggregateFieldValues,
              color,
              hex: '',
              records: [],
            })
            colorIndex++
          }
          break
        }
        case 'sum': {
          // Push each row with the SUM of the field values
          if (!chartDataItems?.find((option) => option.name === stringValue)) {
            chartDataItems.push({
              name: stringValue,
              value: getSummaryTypeValueSum(
                aggregateField,
                aggregateFieldValues,
              ),
              values: aggregateFieldValues,
              color,
              hex: '',
              records: [],
            })
            colorIndex++
          }
          break
        }

        case 'average': {
          // Push each row with the SUM of the field values
          if (!chartDataItems?.find((option) => option.name === stringValue)) {
            chartDataItems.push({
              name: stringValue,
              value: getSummaryTypeValueAverage(
                aggregateField,
                aggregateFieldValues,
              ),
              values: aggregateFieldValues,
              color,
              hex: '',
              records: [],
            })
            colorIndex++
          }
          break
        }
        case 'min': {
          // Push each row with the MIN of the field values
          if (!chartDataItems?.find((option) => option.name === stringValue)) {
            chartDataItems.push({
              name: stringValue,
              value: getSummaryTypeValueMin(
                aggregateField,
                aggregateFieldValues,
              ),
              values: aggregateFieldValues,
              color,
              hex: '',
              records: [],
            })
            colorIndex++
          }
          break
        }
        case 'max': {
          // Push each row with the MAX of the field values
          if (!chartDataItems?.find((option) => option.name === stringValue)) {
            chartDataItems.push({
              name: stringValue,
              value: getSummaryTypeValueMax(
                aggregateField,
                aggregateFieldValues,
              ),
              values: aggregateFieldValues,
              color,
              hex: '',
              records: [],
            })
            colorIndex++
          }
          break
        }
        case 'median': {
          // Push each row with the MEDIAN of the field values
          if (!chartDataItems?.find((option) => option.name === stringValue)) {
            chartDataItems.push({
              name: stringValue,
              value: getSummaryTypeValueMedian(
                aggregateField,
                aggregateFieldValues,
              ),
              values: aggregateFieldValues,
              color,
              hex: '',
              records: [],
            })
            colorIndex++
          }
          break
        }

        default:
          // case distinct
          if (!chartDataItems?.find((option) => option.name === stringValue)) {
            // If not already found - add new option to chart
            //only consider distinct set if aggregateField is present and at fieldSummary tab
            chartDataItems.push({
              name: stringValue,
              value:
                aggregateField && yAxisType === 'fieldSummary'
                  ? new Set(aggregateFieldValues).size
                  : 1,
              values: aggregateFieldValues,
              color,
              hex: '',
              records: [],
            })
            colorIndex++
          }
          break
      }
    }

    // Step 1.5
    // Find the GroupBy Options => Bar Chart Only
    if (groupByField?.id && chartType === CardType.CHART_BAR) {
      const groupByValues = getFieldDisplayValues(
        record?.getCellValue(groupByField?.name),
        groupByField,
      )
      for (const groupByValue of groupByValues) {
        if (
          groupByValue &&
          !groupByOptions?.find((option) => option?.name === groupByValue)
        ) {
          const groupColour =
            groupByField?.select_options?.find(
              (option) => option?.value === groupByValue,
            )?.color ??
            baserowColors[(colorIndex + 0) % baserowColors.length]?.name

          // If not already found - add new option to chart
          groupByOptions.push({
            name: groupByValue,
            hex: getHexForColor(groupColour),
          })

          colorIndex++
        } else {
          // Check if we want empty vals
          if (
            includeEmptyRecords &&
            !groupByOptions?.find((option) => option?.name === 'Empty') &&
            !groupByValue
          ) {
            groupByOptions.push({
              name: 'Empty',
              hex: getHexForColor(CHART_EMPTY_COLOR),
            })
            colorIndex++
          }
        }
      }
    }
  }

  // Step 2:
  // Breakdown data to get chart item values
  for (const option of chartDataItems) {
    // Find all records with matching values

    const matchingResults = []

    const optionRecords = records.filter((record) => {
      const stringValues = getFieldDisplayValues(
        record?.getCellValue(dataField?.name),
        dataField,
      )

      // Count how many times the value appears
      const filteredResults = stringValues?.filter(
        (value) => value === option?.name,
      )

      // If the result was found in result => count value
      for (const match of filteredResults) {
        matchingResults.push(match)
      }

      // Return row if record appeared
      return stringValues?.includes(option.name)
    })

    // Assign records to ChartItem
    option.records = optionRecords
    // Get the appropriate hex color
    option.hex = getHexForColor(option.color)

    // Step 2.5
    // Assign the values for GroupBy => Bar Chart Only
    if (
      groupByField?.id &&
      chartType === CardType.CHART_BAR &&
      groupByOptions?.length > 0
    ) {
      option.value = 0

      // Loop through the groupByOptions and set them to the ChartItem
      for (const groupByOption of groupByOptions) {
        // Check for a "safe" key
        if (!reservedChartItemKeys?.includes(groupByOption?.name)) {
          const matchedValues = []
          const emptyValues = []

          // Go through each of the attached records and set a groupBy Value
          for (const record of optionRecords) {
            const recordGroupByValues = getFieldDisplayValues(
              record?.getCellValue(groupByField?.name),
              groupByField,
            )
            for (const value of recordGroupByValues) {
              if (value && value === groupByOption?.name) {
                matchedValues?.push({ recordId: record.id, value })
              } else if (!value) {
                emptyValues.push({ recordId: record.id, value: 'Empty' })
              }
            }
          }

          // prepare each option's record value array
          const recordValues = []
          const emptyRecordValues = []
          for (const aggregateFieldValue of aggregateFieldsValues) {
            if (aggregateFieldValue.name === option.name) {
              if (includeEmptyRecords && groupByOption?.name === 'Empty') {
                for (const emptyValue of emptyValues) {
                  const record = aggregateFieldValue.recordsWithValue.find(
                    (record) => record.recordId === emptyValue.recordId,
                  )
                  if (record) {
                    if (aggregateBy === 'distinct') {
                      emptyRecordValues.push(record.recordValue)
                    } else if (!isNil(record.recordValue)) {
                      emptyRecordValues.push(record.recordValue)
                    }
                  }
                }
              }
              //matchedValues contains all the records which match the groupByOption
              for (const value of matchedValues) {
                //for all the records which match the groupByOption, find them in the aggregateFieldValue data in order to get individual record's value
                const record = aggregateFieldValue.recordsWithValue.find(
                  (record) => record.recordId === value.recordId,
                )
                if (record) {
                  if (aggregateBy === 'distinct') {
                    recordValues.push(record.recordValue)
                  } else if (!isNil(record.recordValue)) {
                    recordValues.push(record.recordValue)
                  }
                }
              }
            }
          }
          // start to calculate the value of each option
          if (aggregateBy === 'count') {
            //count the number of matched values
            if (groupByOption?.name === 'Empty') {
              option['Empty'] = emptyValues?.length
              option.value += option['Empty']
            } else {
              option[groupByOption?.name] = matchedValues?.length
              option.value += option[groupByOption?.name] as number
            }
          } else if (aggregateBy === 'distinct') {
            //count the number of distinct matched values
            if (yAxisType === 'recordCount') {
              if (groupByOption?.name === 'Empty') {
                option['Empty'] = new Set(
                  emptyValues.map((value) => value.value),
                ).size
                option.value += option['Empty']
              } else {
                option[groupByOption?.name] = new Set(
                  matchedValues.map((value) => value.value),
                ).size
                option.value += option[groupByOption?.name] as number
              }
            } else {
              //calculate the number of distinct values
              if (emptyRecordValues.length > 0) {
                const emptyValue = new Set(emptyRecordValues).size
                option['Empty'] = emptyValue
                option.value += emptyValue
              }
              if (recordValues.length > 0) {
                const value = new Set(recordValues).size
                option[groupByOption?.name] = value
                option.value += value
              }
            }
          } else if (aggregateBy === 'sum') {
            //each aggregate field value has an Array call recordsWithValue, which contains all the records which contribute to the aggregated value
            if (emptyRecordValues.length > 0) {
              option['Empty'] = sum(emptyRecordValues)
              option['Empty'] = numberFormatter(option['Empty'] as number)
              option.value += option['Empty']
            }
            if (recordValues.length > 0) {
              option[groupByOption?.name] = sum(recordValues)
              option[groupByOption?.name] = numberFormatter(
                option[groupByOption?.name] as number,
              )
              option.value += option[groupByOption?.name] as number
            }
          } else if (aggregateBy === 'average') {
            //calculate the average, and assign to the groupByOption
            if (emptyRecordValues.length > 0) {
              option['Empty'] = mean(emptyRecordValues)
              option['Empty'] = numberFormatter(option['Empty'] as number)
              option.value += option['Empty']
            }
            if (recordValues.length > 0) {
              option[groupByOption?.name] = mean(recordValues)
              option[groupByOption?.name] = numberFormatter(
                option[groupByOption?.name] as number,
              )
              option.value += option[groupByOption?.name] as number
            }
          } else if (aggregateBy === 'min') {
            if (emptyRecordValues.length > 0) {
              option['Empty'] = Math.min(...emptyRecordValues)
              option['Empty'] = numberFormatter(option['Empty'] as number)
              option.value += option['Empty']
            }
            if (recordValues.length > 0) {
              //find the minimum value and assign to the groupByOption
              option[groupByOption?.name] = Math.min(...recordValues)
              option[groupByOption?.name] = numberFormatter(
                option[groupByOption?.name] as number,
              )
              option.value += option[groupByOption?.name] as number
            }
          } else if (aggregateBy === 'max') {
            if (emptyRecordValues.length > 0) {
              option['Empty'] = Math.max(...emptyRecordValues)
              option['Empty'] = numberFormatter(option['Empty'] as number)
              option.value += option['Empty']
            }
            if (recordValues.length > 0) {
              //find the maximum value and assign to the groupByOption
              option[groupByOption?.name] = Math.max(...recordValues)
              option[groupByOption?.name] = numberFormatter(
                option[groupByOption?.name] as number,
              )
              option.value += option[groupByOption?.name] as number
            }
          } else if (aggregateBy === 'median') {
            if (emptyRecordValues.length > 0) {
              option['Empty'] = median(emptyRecordValues)
              option['Empty'] = numberFormatter(option['Empty'] as number)
              option.value += option['Empty']
            }
            if (recordValues.length > 0) {
              option[groupByOption?.name] = median(recordValues)
              option[groupByOption?.name] = numberFormatter(
                option[groupByOption?.name] as number,
              )
              option.value += option[groupByOption?.name] as number
            }
          }
        }
      }
    }
  }

  // Step 3:
  // Filter Empty Records
  if (!includeEmptyRecords) {
    // Remove Empty Records
    chartDataItems = chartDataItems?.filter((item) => item?.name !== '')
  } else {
    // Update Empty Records with new label and color
    chartDataItems = chartDataItems?.map((item) => {
      return {
        ...item,
        name: item?.name || 'Empty',
        hex: item?.name ? item?.hex : getHexForColor(CHART_EMPTY_COLOR),
      }
    })
  }

  // Step 3.5:
  // Determined Chart Options from Single/Multiple Select with 0 results get filtered out
  if (fieldUsesSelectOptions) {
    chartDataItems = chartDataItems?.filter((chartItem) => chartItem?.value > 0)
  }

  // Step 4:
  // Bucket Values by type - currently only for Bar Charts
  if (
    isBucketField(dataField) &&
    chartType === CardType.CHART_BAR &&
    !!bucketValuesBy
  ) {
    const bucketedData = bucketChartData(
      chartDataItems,
      bucketValuesBy,
      dataField,
      groupByField,
      aggregateBy,
      aggregateField,
      yAxisType,
    )

    // Update to Bucketed Data
    chartDataItems = bucketedData
  }

  // Step 5:
  // Sort if required - currently only for Bar Charts
  if (chartType === CardType.CHART_BAR && sortSettings) {
    // Do some sorting
    chartDataItems = sortChartData(
      chartDataItems,
      sortSettings.sortBy,
      sortSettings.sortOrder,
      dataField,
      groupByField,
      bucketValuesBy,
    )
  }

  return { chartDataItems, groupByOptions }
}
