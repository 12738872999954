import type { Dispatch, FC, SetStateAction } from 'react'

import ColorModuleCell from 'src/components/HubDash/CardExpand/InfoPanel/ColorModuleCell'

import type { HubDashCardType } from '../../lib/types'

interface InfoPanelProps {
  card: HubDashCardType
  selectedBorderColor: string
  setSelectedBorderColor: Dispatch<SetStateAction<string>>
  selectedBackgroundColor: string
  setSelectedBackgroundColor: Dispatch<SetStateAction<string>>
  view: any // not typed yet
  records: any[] // not typed yet
}

const InfoPanel: FC<InfoPanelProps> = ({
  card,
  selectedBorderColor,
  setSelectedBorderColor,
  selectedBackgroundColor,
  setSelectedBackgroundColor,
  view,
  records,
}) => {
  return (
    <div className="hide-scrollbar flex flex-col overflow-scroll">
      <ColorModuleCell
        hubDashCardId={card.id}
        selectedBorderColor={selectedBorderColor}
        setSelectedBorderColor={setSelectedBorderColor}
        selectedBackgroundColor={selectedBackgroundColor}
        setSelectedBackgroundColor={setSelectedBackgroundColor}
        view={view}
        records={records}
        card={card}
      />
    </div>
  )
}

export default InfoPanel
