import dayjs, { type Dayjs } from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { clamp, median, sum } from 'ramda'

import { BucketByValues, DateFormatType } from '../../lib/enums'
import type { AnyBaserowField, BarChartAggregateType } from '../../lib/types'

import type { ChartItem } from './getChartData'
import { getFieldDisplayValues } from './getFieldDisplayValues'
import { MAX_DECIMAL_COUNT } from './summaryFunctions'

dayjs.extend(customParseFormat)

const clampValue = (
  valueToClamp: number,
  dataField: AnyBaserowField,
): number => {
  return Number(
    Number(valueToClamp).toFixed(
      clamp(
        0,
        MAX_DECIMAL_COUNT,
        dataField?.number_decimal_places ?? MAX_DECIMAL_COUNT,
      ),
    ),
  )
}

const convertStringToDayJsWithFieldFormat = (
  dateString: string,
  baserowField: AnyBaserowField,
): Dayjs => {
  const dateFormat = baserowField?.date_format
  if (!dateFormat || !['EU', 'ISO', 'US'].includes(dateFormat)) {
    return dayjs(dateString) // fallback to default parsing
  }
  const formatUsedToParse = DateFormatType[dateFormat]
  const timeFormat = baserowField?.date_include_time
    ? baserowField?.date_time_format === '24'
      ? ' HH:mm'
      : ' hh:mma'
    : ''
  return dayjs(dateString, `${formatUsedToParse}${timeFormat}`)
}

const convertDayJsToStringWithDateFormat = (dayJSObj: Dayjs, baserowField) => {
  const dateFormat = DateFormatType[baserowField?.date_format]
  return dayJSObj.format(dateFormat)
}

const getBucketByString = (
  date: Dayjs,
  bucketByValue: BucketByValues,
  dataField: AnyBaserowField,
): string => {
  switch (bucketByValue) {
    case BucketByValues.DAY:
      return `${String(convertDayJsToStringWithDateFormat(date, dataField))}`
    case BucketByValues.WEEK:
      return `${String(date?.startOf('week').format('DD MMM'))} - ${String(date.endOf('week').format('DD MMM'))}, ${String(date?.year())}`
    case BucketByValues.MONTH_OF_YEAR:
      return `${String(date?.format('MMMM'))}`
    case BucketByValues.QUARTER_OF_YEAR:
      return `Q${String(date?.quarter())}`
    case BucketByValues.YEAR:
      return `${String(date?.year())}`
    case BucketByValues.DAY_OF_WEEK:
      return `${String(date?.format('dddd'))}`
    case BucketByValues.MONTH:
      return `${String(date?.format('MMMM'))}, ${String(date?.year())}`
    case BucketByValues.QUARTER:
      return `Q${String(date?.quarter())}, ${String(date?.year())}`
    default:
      return 'Empty'
  }
}

export const bucketChartData = (
  chartItems: ChartItem[],
  bucketByValue: BucketByValues,
  dataField: AnyBaserowField,
  groupByField: AnyBaserowField,
  aggregateBy: BarChartAggregateType,
  aggregateField: AnyBaserowField,
  yAxisType: 'recordCount' | 'fieldSummary',
): ChartItem[] => {
  const bucketedValues: ChartItem[] = []
  for (const bar of chartItems) {
    // Exit early for the Empty Bar
    // Empty Bar will already have correct count and be filtered out if includeEmptyResults is false
    if (bar?.name === 'Empty') {
      const matchedEmpty = bucketedValues?.find(
        (barOption) => barOption?.name === 'Empty',
      )
      if (!matchedEmpty) {
        bucketedValues.push(bar)
      }
    }

    // Convert to DayJS
    const dateValue = convertStringToDayJsWithFieldFormat(bar?.name, dataField)

    // Check for valid date
    if (dateValue.isValid()) {
      // Get Bar Bucket String
      const dateOption = getBucketByString(dateValue, bucketByValue, dataField)

      // Check for matching
      const matchingBar = bucketedValues?.find(
        (barOption) => barOption?.name === dateOption,
      )

      // Push Bar or Update count
      if (!matchingBar) {
        // Push cleaned val without grouped

        const initialBar = {
          name: dateOption,
          color: bar.color,
          hex: bar.hex,
          value: bar.value,
          records: bar.records,
          values: bar.values,
        }

        bucketedValues.push(initialBar)
      } else {
        if (aggregateBy === 'count' || aggregateBy === 'sum') {
          matchingBar.value += bar.value
        } else if (aggregateBy === 'min') {
          matchingBar.value = Math.min(matchingBar.value, bar.value)
        } else if (aggregateBy === 'max') {
          matchingBar.value = Math.max(matchingBar.value, bar.value)
        } else if (aggregateBy === 'average') {
          //do nothing, will be calculated in line 148
        } else if (aggregateBy === 'median') {
          //do nothing, will be calculated in line 153
        } else if (aggregateBy === 'distinct') {
          //do nothing, will be calculated in line 158
        }
        matchingBar.value = clampValue(matchingBar.value, dataField)
        //combine all values and records
        matchingBar.values = [
          ...(matchingBar.values as number[]),
          ...(bar.values as number[]),
        ]

        matchingBar.records = [...matchingBar.records, ...bar.records]
      }
    }
  }

  if (aggregateBy === 'average') {
    for (const bar of bucketedValues) {
      const values = bar.values as number[]
      bar.value = values.length > 0 ? sum(values) / values.length : 0
      bar.value = clampValue(bar.value, dataField)
    }
  }
  if (aggregateBy === 'median') {
    for (const bar of bucketedValues) {
      const values = bar.values as number[]
      bar.value = values.length > 0 ? median(values) : 0
      bar.value = clampValue(bar.value, dataField)
    }
  }
  if (aggregateBy === 'distinct') {
    for (const bar of bucketedValues) {
      if (yAxisType === 'recordCount') {
        bar.value = 1
        continue
      }
      const values = bar.values as number[]
      bar.value = values.length > 0 ? new Set(values).size : 0
      bar.value = clampValue(bar.value, dataField)
    }
  }
  // If groupBy, loop through bucketedValues and apply custom key
  if (groupByField) {
    for (const bucketValue of bucketedValues) {
      const recordValues = []
      //init value to 0
      bucketValue.value = 0
      for (const record of bucketValue.records) {
        const displayValue = getFieldDisplayValues(
          record?.getCellValue(groupByField?.name),
          groupByField,
        )[0]
        //if no display value, means it belongs to empty
        recordValues.push({
          displayValue: displayValue ? displayValue : 'Empty',
          value: Number(record?.getCellValue(aggregateField?.name)),
        })
      }
      const groupedData: { [key: string]: number[] } = recordValues.reduce(
        (acc, { displayValue, value }) => {
          if (!acc[displayValue]) {
            acc[displayValue] = []
          }
          acc[displayValue].push(value)
          return acc
        },
        {},
      )
      if (aggregateBy === 'count') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          bucketValue[displayValue] = values.length
          bucketValue.value += values.length
        }
      } else if (aggregateBy === 'distinct') {
        if (yAxisType === 'recordCount') {
          for (const displayValue of Object.keys(groupedData)) {
            bucketValue[displayValue] = 1
            bucketValue.value += 1
          }
        } else {
          for (const [displayValue, values] of Object.entries(groupedData)) {
            bucketValue[displayValue] = new Set(values).size
            bucketValue.value += new Set(values).size
          }
        }
      } else if (aggregateBy === 'sum') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          bucketValue[displayValue] = clampValue(sum(values), dataField)
          bucketValue.value += clampValue(sum(values), dataField)
        }
      } else if (aggregateBy === 'average') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          if (values.length === 0) continue
          bucketValue[displayValue] = clampValue(
            sum(values) / values.length,
            dataField,
          )
          bucketValue.value += clampValue(
            sum(values) / values.length,
            dataField,
          )
        }
      } else if (aggregateBy === 'min') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          const minValue = values.reduce(
            (min, val) => Math.min(min, val),
            values[0],
          )
          bucketValue[displayValue] = minValue
          bucketValue.value += minValue
        }
      } else if (aggregateBy === 'max') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          const maxValue = values.reduce(
            (max, val) => Math.max(max, val),
            values[0],
          )
          bucketValue[displayValue] = maxValue
          bucketValue.value += maxValue
        }
      } else if (aggregateBy === 'median') {
        for (const [displayValue, values] of Object.entries(groupedData)) {
          bucketValue[displayValue] = median(values)
          bucketValue.value += median(values)
        }
      }
    }
  }
  return bucketedValues
}
